import { graphql } from "gatsby"
import React from "react"
import AreaGuideDetails from "../components/AreaGuideDetails/AreaGuideDetails"
import AreaguideSlider from "../components/AreaguideSlider/AreaguideSlider"
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs"
import Footer from "../components/Footer/Footer"
import Header from "../components/Header/Header"
import { buyURL } from "../site/urls"
import BranchProperties from "./branch-properties"
import SEO from "../components/Seo/seo"
import useCompanyInfo from "../hooks/useCompanyInfo"
import useDeviceMedia from "../hooks/useDeviceMedia"
import { getWhatsAppURL } from "../components/common/site/utils"
import balckPhoneImg from "../images/black-phone.svg"
import whatsappImg from "../images/whatsapp.svg"
import MobileContact from "../components/MobileContact/MobileContact"
import LandingStructuredData from "../components/Seo/LandingStructuredData"

const AreaGuideDetailsTemplate = ({ data }) => {
  const { phone } = useCompanyInfo()
  const {isLargeScreen,isMobile}=useDeviceMedia()
  const areaguideData = data.glstrapi.areaGuide

  const title = `more Areas in Dubai ${areaguideData.name}`
  const seoDescription =
    `Wondering where to live in Dubai! Check the ${areaguideData.name} area guide and find your dream home in the most wonderful communities of Dubai, spanning from Burj Khalifa to The Palm Jumeirah. Have a look at the best places to buy property in Dubai`
    const description =
    `<p>Wondering where to live in Dubai! Check the ${areaguideData.name} area guide and find your dream home in the most wonderful communities of Dubai, spanning from Burj Khalifa to The Palm Jumeirah. Have a look at the best places to buy property in Dubai</p>`
  const areaData = { title, description }
  const staticURLs = [
    { url: buyURL, label: "Buy" },
    { url: "", label: areaguideData.name },
  ]

  return (
    <div className="areaguide-details-page">
      <SEO title={areaguideData.name} description={seoDescription} />
      <LandingStructuredData description={seoDescription}/>
      <Header />
      {!isLargeScreen&&
       <div className="social-section">
          <a href={getWhatsAppURL(phone)} target="_blank">
            <img
              src={whatsappImg}
              alt="social-whatsapp"
              className="social-icon social-whatsapp"
            />
          </a>
          <a href={"tel:" + phone}>
            <img
              src={balckPhoneImg}
              alt="social-phone"
              className="social-icon social-phone"
            />
          </a>
        </div>
        }
         {
          isMobile&&
          <MobileContact/>
        }
      <Breadcrumbs staticURLs={staticURLs} />
      <AreaGuideDetails areaguideData={areaguideData} />
      <BranchProperties areaguideData={areaguideData} />
      <AreaguideSlider module={areaData} />
      <Footer categoryType="category1"/>
    </div>
  )
}

export default AreaGuideDetailsTemplate

export const pageQuery = graphql`
  query GetAreaguidesPage($articleId: ID!) {
    glstrapi {
      areaGuide(id: $articleId, publicationState: LIVE) {
        id
        seo_meta {
          title
          description
        }
        banner_image {
          url
        }
        slug
        content
        description
        name
        slug
        team {
          ...team
        }
      }
    }
  }
`
