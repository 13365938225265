import React, { useState } from "react"
import { Container, Tab, Tabs } from "react-bootstrap"
import CustomSlider from "../components/CustomSlider/CustomSlider"
import "../components/AreaGuideDetails/AreaGuideProperties.scss"
import {
  inViewOptions2,
  containerVariants,
  fadeInFromTop,
} from "../components/utils/animation"

import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import { Property_Area } from "../queries/common_use_query"
import { numberFormat } from "../components/common/site/utils"
import { propertyForRentDetailURL, propertyForSaleDetailURL } from "../site/urls"
import { Link } from "gatsby"

const BranchProperties = ({ areaguideData }) => {

  const { data } = Property_Area(
    areaguideData?.name,
    "residential"
  )

  const for_sales = []
  const for_rent = []

  data && data?.properties?.length > 0 && data?.properties?.forEach((item) => {
    if (item.search_type === "sales") {
      for_sales.push(item)
    } else if (item.search_type === "lettings") {
      for_rent.push(item)
    }
  })

  const KEYS = {
    sale: "for-sale",
    rent: "for-rent",
    offplan: "off-plan",
  }

  const [key, setKey] = useState(KEYS.sale)

  const PropertyCard = ({ imageUrl, type, price, address, description, search_type, slug, id }) => {

    const pathURL = search_type === "sales" ? propertyForSaleDetailURL : propertyForRentDetailURL
    return (
      <motion.div
        className="areaguide-branch-property-card"
        variants={fadeInFromTop}
      >
        <Link
          to={pathURL + slug + "-" + id}
        >
          <div className="image-section">
            <img src={imageUrl} alt="property-image" className="property-image" />
            <div className="image-tag">
              <p className="tag-name">{type}</p>
            </div>
          </div>
        </Link>
        <div className="content">
          <Link to={pathURL + slug + "-" + id} >
            <p className="price">AED {numberFormat(price)}</p>
            <p className="address">{address}</p>
          </Link>
          <p className="description">{description}</p>
        </div>
      </motion.div>
    )
  }


  return (
    <InView {...inViewOptions2}>
      {({ ref, inView }) => (
        <motion.div
          className="animation-component"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}
        >
          {(for_sales.length > 0 || for_rent.length > 0) && 
          <div className="areaguide-branch-properties-wrap">
            <div className="areaguide-branch-component">
              <Container className="areaguide-branch-properties-container">
                <h2 className="title">Properties in {areaguideData.name}</h2>
                <Tabs
                  activeKey={key}
                  onSelect={k => setKey(k)}
                  className="areaguide-branch-properties-tab-section"
                >
                  {for_sales.length > 0 && <Tab eventKey={KEYS.sale} title="For Sale">
                    <CustomSlider className="areaguide-branch-slider-wrap" length={for_sales?.length}>
                      {for_sales.map(property => (
                        <PropertyCard
                          address={property.display_address}
                          description={property.description}
                          imageUrl={property?.images[0]?.url}
                          price={property.price}
                          type={property.building[0]}
                          key={property.id}
                          search_type={property.search_type}
                          slug={property.slug}
                          id={property.id}
                        />
                      ))}
                    </CustomSlider>
                  </Tab>}
                  {for_rent.length > 0 && <Tab eventKey={KEYS.rent} title="For Rent">
                    <CustomSlider className="areaguide-branch-slider-wrap" length={for_rent?.length}>
                      {for_rent.map(property => (
                        <PropertyCard
                          address={property.display_address}
                          description={property.description}
                          imageUrl={property?.images[0]?.url}
                          price={property.price}
                          type={property.building[0]}
                          key={property.id}
                          search_type={property.search_type}
                          slug={property.slug}
                          id={property.id}
                        />
                      ))}
                    </CustomSlider>
                  </Tab>}
                  {/* todo: offplan tab */}
                  {/* <Tab eventKey={KEYS.offplan} title="Offplan">
          <></>
        </Tab> */}
                </Tabs>
              </Container>
            </div>
          </div>
          }
       </motion.div>
      )}
    </InView>
  )
}

export default BranchProperties
