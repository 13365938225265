import React, { useState } from "react"
import { Container } from "react-bootstrap"
import parse from "html-react-parser"
import "./AreaGuideDetails.scss"
import { graphql, Link, useStaticQuery } from "gatsby"
import StaticContactCard from "../StaticContactCard/StaticContactCard"
import {
  listYourProperty,
  propertyForRentURL,
  propertyForSaleURL,
} from "../../site/urls"
import GetGGFXImage from "../common/site/GetGGFXImage"

const AreaGuideDetails = ({ areaguideData }) => {
  const data = useStaticQuery(graphql`
    query getFaq {
      glstrapi {
        globalModule {
          AreaGuide_Faq {
            theme
            title
            id
            faq {
              id
              answer
              question
            }
          }
        }
      }
    }
  `)
  const [selected, setSelected] = useState(null)

  const faqData = data.glstrapi.globalModule.AreaGuide_Faq[0]

  const {
    name,
    description,
    content,
    banner_image,
    team,
    id,
    imagetransforms,
  } = areaguideData
  const title = `Are you looking to List Your Property in ${name}?`
  const desc = "Get in touch with our experts today!"
  const staticData = {
    desc,
    title,
  }

  const clickHandler = i => {
    if (selected === i) {
      return setSelected(null)
    }
    setSelected(i)
  }

  let processedImages = imagetransforms?.tile_image_Transforms
  processedImages ||= JSON.stringify({})
  return (
    <>
      <div className="areaguide-details-wrapper">
        <Container>
          <>
            <div className="areaguide-top-content">
              <h1>{`${name} area guide`}</h1>
              <p>{parse(description)}</p>
            </div>

            <div className="property-list-links area-guides">
              <Link to={propertyForSaleURL + "in-dubai/"}>
                Property for Sale
                <span>
                  <i className="icon white-arrow-up-right" />
                </span>
              </Link>

              <Link to={propertyForRentURL + "in-dubai/"}>
                Property for Rent
                <span>
                  <i className="icon white-arrow-up-right" />
                </span>
              </Link>

              <Link to={listYourProperty}>
                List Your Property
                <span>
                  <i className="icon white-arrow-up-right" />
                </span>
              </Link>
            </div>
            <div className="areaguide-details-sections">
              <div className="areaguide-details-left">
                <div className="areaguide-image">
                  {/* <img src={banner_image.url} alt={name} /> */}
                  <GetGGFXImage
                    imagename={"area-guides.tile_image.tiledetail"}
                    imagesource={banner_image}
                    fallbackalt={name}
                    imagetransformresult={processedImages}
                    id={id}
                    className=""
                  />
                </div>
                <div className="areaguide-textcontent">{parse(content)}</div>

                {/* <div className="faq-module-wrapper section-p80">
                  <div className="faq-module-container">
                    <h3 className="faq-section-title">{faqData.title}</h3>
                    {faqData.faq?.map((data, index) => (
                      <div className="faq-section" key={index}>
                        <div className="accordion accordion-flush">
                          <div className="accordion-item">
                            <h2 className="accordion-header">
                              <button
                                type="button"
                                className={
                                  selected === index
                                    ? "accordion-button"
                                    : "accordion-button collapsed"
                                }
                                id="accordion-button-id"
                                onClick={() => clickHandler(index)}
                              >
                                <h4>{data.question}</h4>
                              </button>
                            </h2>
                            <div
                              className={
                                selected === index
                                  ? "accordion-collapse collapse show"
                                  : "accordion-collapse collapse"
                              }
                              id="accordion-content"
                            >
                              <div className="accordion-body">
                                {parse(data.answer)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div> */}
              </div>
              <div className="areaguide-details-right">
                <StaticContactCard content={staticData} team={team} />
              </div>
            </div>
          </>
        </Container>
      </div>
    </>
  )
}

export default AreaGuideDetails
