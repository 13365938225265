import { useQuery } from "@apollo/client"
import { Queries } from "./common_queries"

export const People_Email = name => {
  return useQuery(Queries.People_Email, {
    variables: { name: name },
  })
}

export const Property_Area = (area,department) => {
  return useQuery(Queries.Property_Area, {
    variables: { area: area,department: department },
  })
}

