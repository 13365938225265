import gql from "graphql-tag"

const People_Email = gql`
  query GetPeopleEmail($name: String!) {
    teams(where: { name: $name }) {
      name
      email
      mobile_no
      designation
      id
      slug
      image {
        url
        alternativeText
      }
      imagetransforms
      languages
    }
  }
`

const Property_Area = gql`
  query GetProperties($area: String!, $department: String!) {
    properties(
      where: { area_contains: $area, department: $department, publish: true }
      limit: 3
    ) {
      id
      area
      department
      description
      display_address
      extra
      building
      images
      imagetransforms
      introtext
      max_price
      price
      price_qualifier
      property_url
      slug
      title
      status
      video_tour
      virtual_tour
      address
      search_type
    }
  }
`

export const Queries = {
  People_Email,
  Property_Area,
}
